<template>
  <div class="goods">
    <h1 class="text-center">Продукция</h1>
    <ul>
      <li
          v-for="good in goods.data"
          :key="good.barcode">
        {{ good.brand }} - {{ good.name }} - {{ good.price }} ₽</li>
    </ul>
  </div>
</template>

<script>
import HTTP from "../api";

export default {
  data() {
    return {
      goods: null
    };
  },
  mounted() {
    HTTP
        .get('goods/')
        .then(response => (this.goods = response));
  },
}
</script>
